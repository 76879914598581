import React from "react";
import { useTranslation } from "react-i18next";
import { css } from "goober";
import { Card } from "clutch/src/Card/Card.js";

import CloseIcon from "@/inline-assets/close-icon.svg";
import HelpIcon from "@/inline-assets/nav-help.svg";
import { SquareAvatar } from "@/shared/Avatar.jsx";
import { classNames } from "@/util/class-names.mjs";

const Styled = {
  alertCss: () => css`
    border: 1px solid var(--card-inset-border, var(--shade3-25));
    margin: 0 var(--sp-3) var(--sp-3);
    padding: var(--sp-3) var(--sp-4);

    button {
      cursor: pointer;
      padding: 0;
      background: transparent;
    }
  `,
};

// https://www.figma.com/file/0Zv8KNpb0vdCvsZgK2Nj7F/%5BClutch%5D-Organisms?node-id=2%3A164&mode=dev
// TODO: the spec implies some extra logic paging that is left un-emplemented for now
export function StatsCard({
  title,
  className,
  children,
}: {
  title: string;
  className?: string;
  /// *Should* be <StatsCard.Tiles> or <StatsCard.Alert>
  children: React.ReactNode;
}) {
  return (
    <Card
      as="section"
      title={title}
      padding="var(--sp-3)"
      {...classNames("stats-card", className)}
    >
      {children}
    </Card>
  );
}

StatsCard.Alert = StatsAlert;
StatsCard.Tile = StatsTile;

// https://www.figma.com/file/0Zv8KNpb0vdCvsZgK2Nj7F/%5BClutch%5D-Organisms?node-id=895%3A32755&mode=dev
function StatsAlert({
  text,
  onDismiss,
  className,
}: {
  text: string | React.ReactNode;
  onDismiss?(): void;
  className?: string;
}) {
  const { t } = useTranslation();
  return (
    <div
      {...classNames(
        Styled.alertCss(),
        "flex gap-3 br align-center justify-between stats-alert",
        className,
      )}
    >
      <div className="flex align-center gap-1 shade1 type-caption">
        <HelpIcon />
        {typeof text === "string" ? (
          <span className="w-full alert-text">{text}</span>
        ) : (
          text
        )}
      </div>
      {onDismiss && (
        <button className="alert-close" onClick={onDismiss}>
          <CloseIcon aria-hidden width={20} />
          <span className="visually-hidden">
            {t("common:dismiss", "Dismiss")}
          </span>
        </button>
      )}
    </div>
  );
}

// https://www.figma.com/file/0Zv8KNpb0vdCvsZgK2Nj7F/%5BClutch%5D-Organisms?node-id=1965%3A33923&mode=dev
export function StatsTile({
  title,
  subtitle,
  avatar,
  href,
  firstStat,
  secondStat,
  className,
}: {
  title: string;
  subtitle?: string;
  avatar: string | React.ReactNode;
  href?: string;
  firstStat: string;
  secondStat: string;
  className?: string;
}) {
  return (
    <div
      {...classNames("flex gap-2 justify-between pad-3 stats-tile", className)}
    >
      <SquareAvatar
        avatar={avatar}
        avatarWidth={40}
        className="stat-avatar"
        title={
          <div className="flex column align-start shade1 type-caption info-column">
            <p className="shade0 type-caption--bold title">{title}</p>
            <p className="subtitle">{subtitle}</p>
          </div>
        }
        href={href}
      />
      <div className="flex column align-end shade1 type-caption info-column">
        <p className="type-caption--bold first-stat">{firstStat}</p>
        <p className="second-stat">{secondStat}</p>
      </div>
    </div>
  );
}
